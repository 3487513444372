<template>
    <section v-if="cargandoContrato">
      <SpinnerComponent :spinning="cargandoContrato" text="Cargando contratos..."/>
    </section>
  <section v-else>
    <p class="text-h6 secondary--text">
      Seguimiento de orden de compra {{ seguimientoContrato?.contrato?.numero }}
    </p>
    <v-row no-gutters align="center">
      <v-col v-if="selectedProvider && seguimientoContrato" cols="12" md="3">
        <info-producto-component :showEyeButton="handleVisibility" hideMount />
      </v-col>
      <v-col cols="12" md="7">
        <p class="text-h6 secondary--text">
          {{ seguimientoContrato?.contrato?.nombre_contrato || "" }}
        </p>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn
          v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
          :disabled="+montos.monto_cancelado === 0 || esAdministradorDeContrato "
          block
          class="white--text"
          color="success"
          @click="liquidacionModal = true"
          >Liquidar compra</v-btn
        >
      </v-col>
    </v-row>
    <v-tabs
      v-if="seguimientoContrato?.contrato?.id_tipo_recepcion"
      v-model="tab"
      color="secondary"
      class="mt-5"
      background-color="bgMinsal"
      center-active
      show-arrows
    >
      <v-tab v-for="item in tabsFiltered" :key="item.tab">
        <v-icon color="secondary" small class="mr-2">
          {{ item.icon }}
        </v-icon>
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <template v-else-if="permitirCambiarRecepcion">
      <v-alert dense type="info">
        Debe configurar el tipo de recepción que tendrá el contrato
      </v-alert>
      <v-autocomplete
        :items="tiposRecepcion"
        item-text="nombre"
        item-value="id"
        label="Tipo de recepción"
        v-model="tipoRecepcion"
      ></v-autocomplete>

      <v-row no-gutters class="d-flex justify-center align-center" style="gap: 16px;">
        <v-btn class="text-uppercase flex-grow-1 secondary" outlined @click="$router.back()">Regresar</v-btn>
        <v-btn @click="actualizarTipoRecepcion" class="text-uppercase flex-grow-1 primary">Actualizar la recepción</v-btn>
      </v-row>
    </template>

    <v-tabs-items
      v-if="seguimientoContrato?.contrato?.id_tipo_recepcion"
      v-model="tab"
    >
      <v-tab-item
        transition="slide-y-transition"
        reverse-transition="fade-transition"
        v-for="item in tabsFiltered"
        :key="item.tab"
      >
        <v-card flat color="bgMinsal">
          <component :is="item.content" class="pa-5"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <dialogoConfirmacion
      :show="liquidacionModal"
      title="¿Desea proseguir con la etapa de liquidación de orden de compra?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="liquidacionModal = false"
      @confirm="liquidarCompra()"
    />
  </section>
</template>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import infoProductoComponent from "@/components/InfoProductoComponent.vue";
import perfilProveedorComponent from "@/components/PerfilProveedorComponent";
import infoOrdenCompraComponent from "./components/InfoOrdenCompraComponent";
import etapaSeguimientoComponent from "./components/EtapaSeguimientoComponent";
import ListadoPagosComponent from "./components/ListadoPagosComponent.vue";
import generalComponent from "./components/generalComponent.vue";
import DocumentosComponent from "./components/DocumentosComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import SancionesComponent from "./components/liquidarOrden/SancionesComponent.vue";
import ReporteSancionComponent from "./components/liquidarOrden/ReporteSancionComponent.vue";
import { SpinnerComponent } from '@/components/utils';
export default {
  name: "seguimientoOrdenCompraView",
  components: {
    perfilProveedorComponent,
    infoOrdenCompraComponent,
    etapaSeguimientoComponent,
    ListadoPagosComponent,
    DocumentosComponent,
    infoProductoComponent,
    generalComponent,
    dialogoConfirmacion,
    SancionesComponent,
    ReporteSancionComponent,
    SpinnerComponent,
  },
  props: {},
  data: () => ({
    tab: null,
    porcentajeSeguimiento: 0,
    infoOrdenCompra: {
      fechaHoraContratacion: "06/09/2022 23:59",
      numOrdenCompra: "0100-2022-OC5165",
      numInternoOrdenCompra: "C-LG-5165",
      montoAdjudicado: "$10,000.00",
    },
    liquidacionModal: false,
    tiposRecepcion: [],
    tipoRecepcion: null,
  }),
  computed: {
    permitirCambiarRecepcion() {
      return !!!this.contrato?.id_tipo_recepcion;
    },
    ...mapState("procesoCompra", ["idOrdenCompra", "etapasList"]),
    ...mapState("seguimientoOrden", [
      "seguimientoContrato",
      "montos",
      "contrato",
      "cargandoContrato",
    ]),
    ...mapState("agregarInsumo", ["selectedProvider"]),
    porcentaje() {
      return (this.porcentajeSeguimiento * 100).toFixed(2);
    },
    handleVisibility() {
      if (this.seguimientoContrato) {
        const { contrato } = this.seguimientoContrato;
        return contrato?.id_forma_contratacion !== 6;
      } else return false;
    },
    tabsFiltered() {
      let tabs = [];
      if (
        ![5, 6].includes(
          this.seguimientoContrato?.contrato?.id_forma_contratacion
        )
      ) {
        tabs = [
          {
            tab: "Recepción",
            content: "etapaSeguimientoComponent",
            icon: "mdi-map-marker-radius",
          },
          {
            tab: "Documentos",
            content: "DocumentosComponent",
            icon: "mdi-file-document-multiple-outline",
          },
          {
            tab: "General",
            content: "generalComponent",
            icon: "mdi-information-outline",
          },
          {
            tab: "Sanciones",
            content: "ReporteSancionComponent",
            icon: "mdi-clipboard-text-clock-outline",
          },
        ];
      }
      if (this.haveRole("ROLE_UFI")) {
        tabs.splice(1, 0, {
          tab: "Pagos",
          content: "ListadoPagosComponent",
          icon: "mdi-currency-usd",
        });
      }
      return tabs;
    },
    esAdministradorDeContrato(){
      return this.seguimientoContrato?.esAdministradorContrato
    }
  },
  watch: {},
  methods: {
    ...mapActions("seguimientoOrden", ["getContrato"]),
    ...mapActions("procesoCompra", ["getEtapasContrato"]),
    ...mapMutations("procesoCompra", ["setIdOrdenCompra"]),
    ...mapMutations("agregarInsumo", ["setCurrentProvider", "setProviderInfo"]),
    ...mapMutations("seguimientoOrden", [
      "obtenerContrato",
      "setMontos",
      "setCargandoContrato",
    ]),
    async getProgresoSeguimiento() {
      const { data } =
        await this.services.ContratoService.getProgresoEtapasSeguimiento(
          this.idOrdenCompra
        );
      this.porcentajeSeguimiento = data.progreso;
    },
    liquidarCompra() {
      this.$router.push({
        name: "liquidacion-orden-compra",
        params: { idContrato: Number(this.$route.params.idContrato) },
      });
    },
    async getMontosContrato() {
      const { data } = await this.services.ContratoService.getMontosPagos(
        this.$route.params.idContrato
      );
      this.setMontos(data);
    },
    async obtenerTiposRecepciones() {
      const { data } =
        await this.services.SeguimientoContractual.obtenerTiposRecepciones();
      this.tiposRecepcion = data;
    },
    async actualizarTipoRecepcion() {
      if (!this.tipoRecepcion)
        return this.pushAppMessage({
          message: "Seleccione el tipo de recepción",
          type: "error",
        });
      await this.services.SeguimientoContractual.actualizarTipoRecepcion(
        this.$route.params.idContrato,
        this.tipoRecepcion
      );
      window.location.reload();
    },
  },
  mounted() {},
  async created() {
    this.setIdOrdenCompra(this.$route.params.idContrato);
    this.getMontosContrato();
    await this.getContrato(this.$route.params.idContrato);
    this.setCurrentProvider(this.$route.params.idProveedor);
    this.getProgresoSeguimiento();
    await this.obtenerTiposRecepciones();
    await this.getEtapasContrato();
  },
  unmounted() {
    this.obtenerContrato(null);
    this.setProviderInfo({});
    this.setCargandoContrato(false);
  },
  beforeDestroy() {
    this.setMontos({});
    this.setCurrentProvider(null);
    this.setCargandoContrato(false);
  },
};
</script>
